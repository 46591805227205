import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import CaseStudiesDetailsContent from '../../components/CaseStudiesDetails/CaseStudiesDetailsContent'
import banner_img from '../../assets/images/projects/project2.jpg'

const facts = [
    {
        id: 0,
        className: 'postgresql',
        label: 'South Africa is known to have one of the largest coal reserves in the world, estimated to be over 30 billion tons. These coal deposits are mainly concentrated in the Mpumalanga province.',
    },
    {
        id: 1,
        className: 'elasticsearch',
        label: 'Coal mining has a long history in South Africa, dating back to the late 19th century. It played a crucial role in the countrys industrialization and economic development.',
    },
    {
        id: 2,
        className: 'opensearch',
        label: 'Coal has been a primary source of energy in South Africa for many decades. It is used extensively for electricity generation, with coal-fired power plants providing a significant portion of the countrys electricity.',
    },
    {
        id: 3,
        className: 'opensearch',
        label: ' South Africa is one of the worlds major coal exporters. The country exports coal to various international markets, including Asia, Europe, and the Middle East.',
    },
];

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Case Studies Details" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Case Studies Details" 
            />
            <CaseStudiesDetailsContent
                banner_img={banner_img}
                facts={facts}
                client={"South African Coal Mine"}
                location={"Johanessburg, South Africa"}
                technologies={"Mining, Coal, Production Effeciency"}
                completed={"2023"}
                website={"https://neolytics.io"}
                type={"Mining"}
                title={"South African Coal Mine, R700m in throughput improvement initiatives"}
                description={"Modelled out both plant and mining performance and identified key initiatives to reduce downtime and cycle time"}
                important_facts={""}
                long_description1={"In a comprehensive analysis, we meticulously modeled both plant and mining performance, delving deep into the intricacies of the operations. Our exhaustive assessment involved scrutinizing every facet of the process, from the initial extraction of resources to the final stages of plant operation. Through this rigorous evaluation, we identified crucial initiatives aimed at minimizing downtime and cycle times. These initiatives were carefully tailored to address specific bottlenecks and inefficiencies within the system, ensuring a holistic approach to optimization. By implementing these strategic measures, we not only enhance operational efficiency but also pave the way for increased productivity, reduced costs, and improved overall performance across the mining and plant sectors, thus fostering sustainable growth and success."}
                long_description2={""}
                results={"Published or shared upon request and in compliance with regulations."}
            />
            {/* <RelatedProjects /> */}
            <Footer />
        </Layout>
    );
}

export default CaseDetails